import { Contract, StrapiFile, UserContract } from '.';

export interface User {
  username: string;
  email: string;
  provider: string;
  confirmed: boolean;
  blocked: boolean;
  createdAt: string;
  updatedAt: string;
  firstName: string;
  lastName: string;
  company?: any;
  presentation?: any;
  phoneNumber?: string;
  whatsAppNumber?: any;
  textNumber?: any;
  role: { name: string };
}

export interface Partner extends User {
  profilePicture?: StrapiFile & { id: number };
}

export interface Client extends User {
  id: number;
  profilePicture?: StrapiFile & { id: number };
  partner: Partner & { id: number };
  productWallets: (UserContract & { id: number })[];
}
