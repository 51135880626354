import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from '@sentry/capacitor';
import * as SentryAngular from '@sentry/angular-ivy';
import * as SentryBrowser from "@sentry/browser";

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

if (environment.SENTRY_ENV != 'local') {
  Sentry.init(
    {
      dsn: environment.SENTRY_LINK,
      integrations: [
        SentryAngular.browserTracingIntegration(),
        SentryAngular.replayIntegration({
          maskAllText: false,
          maskAllInputs: true,
        }),
        SentryAngular.httpClientIntegration({
          failedRequestStatusCodes: [[404, 999]],
          failedRequestTargets: [environment.baseUrl],
        }),
        SentryAngular.captureConsoleIntegration({
          levels: ['error'],
        }),
      ],
      tracePropagationTargets: [environment.baseUrl],
      environment: environment.SENTRY_ENV,
      tracesSampleRate: 1.0,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    },
    SentryAngular.init
  );
}
