import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ToastController } from '@ionic/angular';
import { TranslocoService } from "@ngneat/transloco";
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UserService } from "../../services/user/user.service";

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptor implements HttpInterceptor {
  constructor (private toastController: ToastController, private transloco: TranslocoService, private user: UserService) {

  }

  intercept (req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      untilDestroyed(this),
      catchError((error: HttpErrorResponse) => {
        if (error.error) {
          let message: string
          switch (error.error.status) {
            case 401:
            case 403:
              message = this.transloco.translate("Your session has expired");
              this.user.logout(true);
              break;
            default :
              message = error.error.message ? this.transloco.translate(error.error.message) : this.transloco.translate("SERVER_ERROR");
              break;
          }
          this.toastController.create({
            message: message,
            duration: 6000,
            position: 'bottom',
            color: 'danger',
            header: 'Erreur',
            buttons: [{
              text: 'Fermer',
              role: 'cancel'
            }]
          }).then(toast => toast.present());
        }
        throw error.error;
      })
    );
  }
}
