import { Injectable, Injector } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UserService } from '../../services/user/user.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  constructor(private injector: Injector, private userService: UserService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.userService.token;
    let httpReq = req.clone();
    if (token) {
      const newHeaders = req.headers.set('Authorization', `Bearer ${token}`);
      httpReq = req.clone({
        headers: newHeaders,
      });
    }
    return next.handle(httpReq).pipe(
      catchError((error: HttpErrorResponse) => {
        throw error.error;
      }),
      untilDestroyed(this)
    );
  }
}
