import { Injectable } from '@angular/core';

import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private storage: Storage | null = null;

  constructor(private storageApi: Storage) {
    this.init();
  }

  async init() {
    const storage = await this.storageApi.create();
    this.storage = storage;
  }

  public set(key: string, value: any) {
    this.storageApi?.remove(key);
    this.storageApi?.set(key, value);
  }

  public get(key: string) {
   return this.storageApi?.get(key);
  }
}
