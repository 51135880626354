import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { UserService } from '../services/user/user.service';
import { map } from 'rxjs/operators';
import { RolesEnum } from '../models/enums/roles.enum';
import { StorageService } from '../services/storage/storage.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(private router: Router, private userService: UserService, private storage: StorageService) {}

  canActivate(route: ActivatedRouteSnapshot) {
    const allowedRoles: RolesEnum[] = route.data['allowedRoles'];
    return this.userService.onlineUser$.pipe(
      map((user) => {
        if (!user) {
          this.router.navigate(['/auth']);
          return false;
        }
        if (allowedRoles.find((role) => role === user.role.name)) {
          return true;
        }
        return false;
      }),
      untilDestroyed(this)
    );
  }
}

export const canActivate: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return inject(AuthGuard).canActivate(route);
};
