export const enum TimeRangeEnum {
    QUARTER = "QUARTER",
    HALF_YEAR = "HALF_YEAR",
    MAX = "MAX"
}

export const TimeRangeList: TimeRangeEnum[] = [
    TimeRangeEnum.MAX,
    TimeRangeEnum.HALF_YEAR,
    TimeRangeEnum.QUARTER,
]