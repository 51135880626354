import { Data, Product, ProductMovement, User, UserProductWalletPerf } from '.';

export interface Contract {
  createdAt: string;
  updatedAt: string;
  totalInvestedValueInEuro: string;
  user: { data: Data<User> };
  product: { data: Data<Product> };
  latestUserProductWalletPerf: { data: Data<UserProductWalletPerf> };
  deposits: { data: Data<ProductMovement>[] };
  withdrawals: { data: Data<ProductMovement>[] };
}

export interface UserContract {
  createdAt: string;
  updatedAt: string;
  totalInvestedValueInEuro: string;
  product: Product & { id: number };
}
